import memoize from '@jetbrains/ring-ui/components/global/memoize'

import type {BuildType} from '../../../../../services/rest'
import type {
  BuildTypeId,
  BuildTypeInternalId,
  NormalizedProject,
  ProjectId,
  ProjectInternalId,
} from '../../../../../types'
import type {KeyValue} from '../../../../../utils/object'
import type {CleanupProjectPageUseIsReadOnlyFragment$key} from '../../__generated__/CleanupProjectPageUseIsReadOnlyFragment.graphql'

export enum FilterBranchStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL_BRANCHES = 'allBranches',
}
export enum FilterBuildStatus {
  ANY = 'any',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
  FAILED_TO_START = 'failed_to_start',
}
export enum FilterPersonal {
  ANY = 'any',
  PERSONAL = 'personal',
  NOT_PERSONAL = 'not_personal',
}

type EmptyObject = {} | null | undefined
export type FilterBranchActivity = {
  activity?: FilterBranchStatus
}
export type FilterPersonalForm = {
  personal: FilterPersonal
}
export type FilterBranchPattern = {
  pattern?: string
}
type Filter = {
  activeBranch?: FilterBranchActivity | null | undefined
  branchPattern?: FilterBranchPattern | null | undefined
  buildStatus?:
    | {
        status: FilterBuildStatus
      }
    | null
    | undefined
  personalBuild?: FilterPersonalForm | null | undefined
  tags?:
    | {
        tagsList: string
      }
    | null
    | undefined
}
export type KeepBuildParts = {
  artifacts?:
    | {
        artifactPatterns?: string
      }
    | null
    | undefined
  everything?: EmptyObject
  logs?: EmptyObject
  history?: EmptyObject
  statistics?: EmptyObject
}
export type Partitions = {
  perBranch?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perPersonal?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perBranchAndActivity?:
    | {
        active?: boolean
      }
    | null
    | undefined
}
export type RuleId = string

/** LIMITS */
type LimitAllName = 'all'
type LimitDaysNames =
  | 'keepNDaysSinceLastBuild'
  | 'keepNDaysSinceLastSuccessfulBuild'
  | 'keepNDaysSinceToday'
type LimitBuildsName = 'keepLastNBuilds'
export type LimitNames = LimitDaysNames | LimitBuildsName | LimitAllName
export type LimitValue = 'daysCount' | 'buildsCount'
export type Limit = KeyValue<LimitNames, KeyValue<LimitValue, number> | null | undefined>
type Params = {}

/** CLEANUP HOLDER CALCULATION */
export type CleanupHolderNode =
  | {
      nodeType: 'project'
      id: ProjectId
      internalId: ProjectInternalId | null | undefined
      name?: string
    }
  | {
      nodeType: 'bt'
      id?: BuildTypeId
      internalId: BuildTypeInternalId | null | undefined
      name?: string
    }
export const getCleanupBuildTypeNode = memoize(
  ({id, internalId, name}: Partial<BuildType>): CleanupHolderNode => ({
    nodeType: 'bt',
    id,
    internalId,
    name,
  }),
)
export const getCleanupProjectNode = memoize(
  ({
    id,
    internalId,
    name,
  }: Partial<NormalizedProject> & {
    id: ProjectId
  }): CleanupHolderNode => ({
    nodeType: 'project',
    id,
    internalId,
    name,
  }),
)

/** RULE */
type CleanupHierarchyTypes = 'own' | 'effective'
export type FetchRulesOptons = {
  hierarchy?: CleanupHierarchyTypes
  getChildrensRules?: ('withAllInheritors' | 'withOwnBuildTypes') | null | undefined
}
export type RuleCommon = {
  ruleId?: RuleId
  ruleDisabled: boolean
}
export type RuleParameters = {
  keepBuildParts: KeepBuildParts
  filters: Filter
  partitions: Partitions
  limit: Limit
  params: Params
  preserveArtifacts: boolean | null | undefined
}
type RuleMeta = {
  source?: 'own' | 'inherited' | 'enforced'
  holder?: ('buildType' | 'project') | null | undefined
  holderExternalId?: (BuildTypeId | ProjectId) | null | undefined
  overridesOwn?: boolean | null | undefined
}
export type Rule = RuleCommon & RuleMeta & RuleParameters

/** REST **/
export enum AnswerStatuses {
  SUCCESS = 'success',
  ERROR = 'error',
}
export type ErrorAnswer = {
  status: typeof AnswerStatuses.ERROR
  code?: string
  errorId: string
  errorType?: string
  message: string
}
type SuccessAnswer<T> = {
  status: typeof AnswerStatuses.SUCCESS
  data: T
}
/**
 *
 * Pay attention, that we have JSend format here,
 * which contains 'data' and 'error' fields,
 * which could be used to handle any sort of errors
 * in a specific way, but now it's not used
 *
 * Look more: https://github.com/omniti-labs/jsend
 *
 * */
export type JSendResponse<T> = SuccessAnswer<T>
export type HolderRule =
  | {
      holder: 'project'
      holderExternalId: ProjectId
      rules: ReadonlyArray<Rule>
    }
  | {
      holder: 'buildType' | 'template'
      holderExternalId: BuildTypeId
      rules: ReadonlyArray<Rule>
    }
export type GetRulesResponse = JSendResponse<{
  holdersWithRules: ReadonlyArray<HolderRule>
}>
export type CreateRuleAnswer = JSendResponse<{
  ruleId: RuleId
}>

/** PROPS **/
export type OuterProps = {
  buildTypeId: BuildTypeId | null | undefined
  projectId: ProjectId
  isTemplate: boolean
  inheritedVisible?: boolean
  embedded?: boolean
  hideOwn?: boolean
  hideInherited?: boolean
  hideEnforced?: boolean
  holderNode: CleanupHolderNode
}
export type OwnProps = OuterProps & {
  readOnlyProjectsKey: CleanupProjectPageUseIsReadOnlyFragment$key | null
}
type VisibilityProps = {
  isVisible: boolean | null | undefined
}
export type Props = OwnProps & VisibilityProps
