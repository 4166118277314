import type {GetAllBuildTypesApiArg, GetBuildTypeApiArg} from '../services/rest'
import {stringifyId} from '../types'
import type {BuildTypeId, RequestOptionsParams} from '../types'

export const BuildTypeProperties = {
  RUN_BUTTON_CAPTION: 'teamcity.ui.runButton.caption',
  DEFAULT_BUILDTYPE_TAB: 'teamcity.ui.buildType.defaultTab',
  DEFAULT_PROJECT_TAB: 'teamcity.ui.project.defaultTab',
  PROMOTE_ONLY: 'teamcity.buildType.environmentBuildType.promoteOnly',
}
const descriptionField = 'description'
const pauseCommentField = 'pauseComment(text,timestamp,user)'
export const linksField = 'links(link(type,relativeUrl))'
export const parametersField =
  'parameters($locator(name(matchType:starts-with,value:teamcity)),property(name,value))'
const snapshotDependenciesField = 'snapshot-dependencies(snapshot-dependency(id))'
const shortProjectDetailsField = 'project(id,virtual,parentProjectId)'
const basicBuildTypeFields = ['id', 'paused', 'internalId', 'projectId', 'name', 'type']
export const buildTypeFields: (options?: RequestOptionsParams) => ReadonlyArray<string> = options =>
  basicBuildTypeFields
    .concat(options?.withLinks === true ? linksField : [])
    .concat(options?.withParameters === true ? parametersField : [])
    .concat(options?.withDescription === true ? descriptionField : [])
    .concat(options?.withSnapshotDependencies === true ? snapshotDependenciesField : [])
    .concat(options?.withPauseComment === true ? pauseCommentField : [])
    .concat(options?.withShortProjectDetails === true ? shortProjectDetailsField : [])

export const getBuildTypeArg = (buildTypeId: BuildTypeId): GetBuildTypeApiArg => ({
  btLocator: `id:${buildTypeId}`,
  fields: buildTypeFields({
    withLinks: true,
    withParameters: true,
    withDescription: true,
    withPauseComment: true,
  }).join(','),
  essential: true,
})

export const getBuildTypesArg = (
  locator: string,
  options?: RequestOptionsParams,
): GetAllBuildTypesApiArg => ({
  locator,
  fields: `buildType(${buildTypeFields(options).join(',')})`,
})

export const getDependantsLocator = (buildTypeId: BuildTypeId): string =>
  `snapshotDependency(from:(id:${stringifyId(buildTypeId)}))`

export const getIsExternalStatusAllowedArg = (buildTypeId: BuildTypeId): GetBuildTypeApiArg => ({
  btLocator: `id:${buildTypeId}`,
  fields: 'externalStatusAllowed',
})
